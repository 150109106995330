import _ from 'lodash'
import Router, { useRouter } from 'next/router'
import queryString from 'query-string'

function getHistoryQuery(search = '') {
  return queryString.parse(search || location.search)
}

function createHistoryQuery(params) {
  return `?${queryString.stringify(params)}`
}

function push({ uri, href, query = {}, isShallow = false }) {
  if (!_.isEmpty(query)) {
    let resolveUri = href || uri
    resolveUri = `${resolveUri}${createHistoryQuery(query)}`
    Router.push(uri, resolveUri, { shallow: isShallow })
  } else if (href) Router.push(uri, href, { shallow: isShallow })
  else Router.push(uri, uri, { shallow: isShallow })
}

function pathname() {
  return Router.pathname
}

function queryParams() {
  const router = useRouter()
  const url = router?.asPath || ''
  const cleanString = url.indexOf('?') !== -1 ? url.substring(url.indexOf('?') + 1) : ''
  return cleanString ? getHistoryQuery(cleanString) : {}
}

function goBack() {
  Router.back()
}

export { getHistoryQuery, push, pathname, goBack, queryParams }
