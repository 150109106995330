import { action, computed, makeObservable, observable } from 'mobx'
import _ from 'lodash'
import { notifyError, notifySuccess, safeObject, validRequired } from 'uikit'
import zxcvbn from 'zxcvbn'
import { prepareErrorMessage } from 'utils/error'
import FactoryModel from 'models/factoryModel'
import FactoryProvider from 'providers/factoryProvider'
import { push } from 'utils/history'
import BaseModel from '../../baseModel'

export const RESTORE_BY_PHONE = 'RESTORE_BY_PHONE'
export const RESTORE_BY_EMAIL = 'RESTORE_BY_EMAIL'

class ResetPasswordModel extends BaseModel {
  ctx = null

  @observable login = ''
  @observable password = ''
  @observable confirmPassword = ''
  @observable code = ''

  @observable contactPhone = ''
  @observable isCodeSent = ''

  @observable isLoading = false
  @observable isFailedSignUp = false
  @observable isEmailSent = false

  @observable restoreMethod = RESTORE_BY_PHONE

  @observable confirmSmsCode = ''
  @observable isSendingConfirmPhone = false
  @observable isEnableSendConfirmPhone = false
  @observable isProcessSendConfirmPhone = false
  CONFIRM_INTERVAL_TIME = 60 // sec
  @observable confirmInterval = this.CONFIRM_INTERVAL_TIME

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get validLogin() {
    if (this.login.trim() === '') return 'Введите логин или email'
    return null
  }

  @computed get validPassword() {
    if (this.password.trim() === '') return 'Укажите пароль'
    if (this.password.trim().length < 6) return 'Длина пароля меньше 6 символов'
    if (zxcvbn(this.password).score < 2) return 'Используйте цифры, буквы и символы'
    return null
  }

  @computed get validConfirmPassword() {
    if (this.password.trim() === '' || this.confirmPassword === '') return 'Укажите пароль'
    if (this.password !== this.confirmPassword) return 'Пароли не совпадают'
    return null
  }

  @computed get validContactPhone() {
    if (this.contactPhone.trim() === '') return 'Введите номер телефона'
    if (this.contactPhone.length !== 10) return 'Неверный формат'
    return null
  }

  @computed get validConfirmSmsCode() {
    if (validRequired(this.confirmSmsCode)) return 'Не указан'
    return null
  }

  clear = () => {
    this.action(() => {
      this.login = ''
      this.password = ''
      this.confirmPassword = ''
      this.code = ''
      this.isLoading = false
      this.isFailedSignUp = false
      this.isEmailSent = false
      this.restoreMethod = RESTORE_BY_PHONE
      this.confirmSmsCode = ''
      this.isSendingConfirmPhone = false
      this.isEnableSendConfirmPhone = false
      this.isProcessSendConfirmPhone = false
      this.confirmInterval = this.CONFIRM_INTERVAL_TIME
      this.clearConfirmInterval()
    })
  }

  setLogin = action(v => {
    this.login = v
    this.isFailedSignIn = false
  })

  setPassword = action(v => {
    this.password = v
    this.isFailedSignIn = false
  })

  setConfirmPassword = action(v => {
    this.confirmPassword = v
    this.isFailedSignIn = false
  })

  setContactPhone = action(v => {
    this.contactPhone = v
  })

  changePassword = async () => {
    try {
      this.applyData({ isLoading: true })
      switch (this.restoreMethod) {
        case RESTORE_BY_EMAIL: {
          await FactoryProvider.AuthProvider.changePasswordByEmail(this.login, this.password, this.confirmPassword, this.code)
          break
        }
        case RESTORE_BY_PHONE: {
          await FactoryProvider.AuthProvider.changePasswordByPhone(this.contactPhone, this.password, this.confirmPassword, this.code)
        }
      }
      notifySuccess('Новый пароль успешно применен')
      push({ uri: '/login' })
    } catch (e) {
      notifyError('Ошибка регистрации', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoading: false })
    }
  }

  restorePassword = async () => {
    try {
      this.applyData({ isLoading: true })
      switch (this.restoreMethod) {
        case RESTORE_BY_EMAIL:
          await FactoryProvider.AuthProvider.restorePasswordByEmail(this.login)
          this.applyData({ isEmailSent: true })
          break
        case RESTORE_BY_PHONE:
          await this.sendConfirmPhone()
      }
    } catch (e) {
      notifyError('Ошибка восстановления пароля', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoading: false })
    }
  }

  clearConfirmInterval = () => {
    if (this.confirmPhoneIntervalID) {
      clearInterval(this.confirmPhoneIntervalID)
      this.confirmPhoneIntervalID = null
    }
  }

  sendConfirmPhone = async () => {
    try {
      this.applyData({
        isProcessSendConfirmPhone: true,
        isEnableSendConfirmPhone: false,
        confirmSmsCode: ''
      })

      await FactoryProvider.AuthProvider.restorePasswordByPhone(this.contactPhone)
      this.applyData({ confirmInterval: this.CONFIRM_INTERVAL_TIME })

      this.clearConfirmInterval()
      this.startInterval()

      this.applyData({ isSendingConfirmPhone: true })
    } catch (e) {
      notifyError('Ошибка отправки sms кода', prepareErrorMessage(e))
      this.applyData({ isEnableSendConfirmPhone: true })
      throw e
    } finally {
      this.applyData({ isProcessSendConfirmPhone: false })
    }
  }

  startInterval = () => {
    this.confirmPhoneIntervalID = setInterval(() => {
      if (this.confirmInterval <= 1) {
        clearInterval(this.confirmPhoneIntervalID)
        this.applyData({
          confirmInterval: this.CONFIRM_INTERVAL_TIME,
          isEnableSendConfirmPhone: true
        })
      } else {
        this.applyData({
          confirmInterval: this.confirmInterval - 1
        })
      }
    }, 1000)
  }

  confirmPhone = async () => {
    try {
      this.applyData({ isProcessSendConfirmCode: true })
      const { code, phone } = await FactoryProvider.AuthProvider.restoreConfirmPhone(this.confirmSmsCode, this.contactPhone)
      push({
        uri: '/users/reset_password_by_phone',
        query: safeObject({
          phone,
          code
        })
      })
    } catch (e) {
      notifyError('Ошибка восстановления пароля', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSendConfirmCode: false })
    }
  }

  handleBack = () => {
    if (this.isEmailSent || this.isSendingConfirmPhone) {
      this.clear()
    } else {
      push({ uri: '/login' })
    }
  }
}

export { ResetPasswordModel }
